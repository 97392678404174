import {cva} from 'class-variance-authority';

import Grid, {Col} from '@/components/base/layouts/utils/Grid/Grid';
import Testimonial from '@/components/base/sections/Testimonial/Testimonial';
import type {TestimonialProps} from '@/components/base/sections/Testimonial/types';
import Image from '@/components/base/elements/Image/Image';
import {twMerge} from '@/stylesheets/twMerge';

const imageStyles = cva(
  'px-9 py-[30px] max-md:px-[20px] bg-black h-full flex items-center justify-center',
  {
    variants: {
      hideImageOnMobile: {
        true: 'max-md:hidden',
        false: '',
      },
    },
  },
);

interface FullWidthTestimonialCardProps extends TestimonialProps {
  containerClassName?: string;
  imageContainerClassName?: string;
  hideImageOnMobile?: boolean;
}

export default function FullWidthTestimonialCard({
  containerClassName,
  imageContainerClassName,
  hideImageOnMobile = true,
  image,
  ...props
}: FullWidthTestimonialCardProps) {
  return (
    <Grid
      className={twMerge(
        'gap-0 full-width-testimonial-card card-box-shadow rounded-2xl overflow-hidden',
        containerClassName,
      )}
    >
      <Col
        span={{xs: 12, md: 8}}
        className="p-lg max-md:pl-[12px] bg-dusky-blue h-full"
      >
        <Testimonial {...props} className="[&>p]:text-t3" />
      </Col>
      <Col
        span={{xs: 12, md: 4}}
        className={twMerge(
          imageStyles({hideImageOnMobile}),
          imageContainerClassName,
        )}
      >
        {image && <Image {...image} />}
      </Col>
    </Grid>
  );
}
