import {cva, type VariantProps} from 'class-variance-authority';
import type {PropsWithChildren} from 'react';
import classNames from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import InlineVideo, {
  type InlineVideoProps,
} from '@/components/pages/shared/InlineVideo/InlineVideo';

const styles = cva(
  'container relative flex flex-col sm:flex-row rounded-2xl overflow-hidden',
  {
    variants: {
      mode: {
        dark: 'bg-fullwidthcard-dark-bg text-fullwidthcard-dark-text',
        light: 'bg-fullwidthcard-light-bg text-fullwidthcard-light-text',
      },
      visualPosition: {
        left: 'sm:flex-row',
        right: 'sm:flex-row-reverse',
      },
    },
    defaultVariants: {
      visualPosition: 'left',
    },
  },
);

interface FullWidthVideoCardProps extends VariantProps<typeof styles> {
  className?: string;
  mediaClassName?: string;
  mode?: 'light' | 'dark';
  video?: InlineVideoProps;
  visualPosition?: 'left' | 'right';
}

export default function FullWidthVideoCard({
  children,
  className,
  mediaClassName = '',
  mode = 'dark',
  video,
  visualPosition = 'left',
}: PropsWithChildren<FullWidthVideoCardProps>) {
  const componentName = 'full-width-card';

  return (
    <div
      className={twMerge(styles({mode, visualPosition}), className)}
      data-mode={mode}
      data-component-name={componentName}
    >
      <div
        className={classNames(
          'flex basis-1/2',
          {
            'place-content-end': visualPosition === 'right',
          },
          mediaClassName,
        )}
      >
        {video && video.video && (
          <InlineVideo {...video} className="w-full h-full" />
        )}
      </div>
      <div className="grid basis-1/2 sm:place-content-center gap-y-md p-8 md:p-10 lg:p-16">
        {children}
      </div>
    </div>
  );
}
